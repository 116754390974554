export const m21 = {
    "points": [
      {
        "IPLYear": 2021,
        "Category": "IPL",
        "CompetitionID": "2021",
        "TeamID": "",
        "TeamCode": "DC",
        "TeamName": "Delhi Capitals",
        "TeamLogo": "https://scores.iplt20.com/ipl/teamlogos/DC.png",
        "Matches": 14,
        "Wins": 10,
        "Loss": 4,
        "Tied": 0,
        "NoResult": 0,
        "Points": 20,
        "Draw": 0,
        "ForTeams": "2180/267",
        "AgainstTeam": "2136/278",
        "NetRunRate": "0.481",
        "Quotient": 0,
        "OrderNo": "1",
        "IsQualified": "1",
        "LeadBy": 0,
        "Deficit": 0,
        "Performance": "L,W,W,L,W"
      },
      {
        "IPLYear": 2021,
        "Category": "IPL",
        "CompetitionID": "2021",
        "TeamID": "",
        "TeamCode": "CSK",
        "TeamName": "Chennai Super Kings",
        "TeamLogo": "https://scores.iplt20.com/ipl/teamlogos/CSK.png",
        "Matches": 14,
        "Wins": 9,
        "Loss": 5,
        "Tied": 0,
        "NoResult": 0,
        "Points": 18,
        "Draw": 0,
        "ForTeams": "2368/272",
        "AgainstTeam": "2218/268.5",
        "NetRunRate": "0.455",
        "Quotient": 0,
        "OrderNo": "2",
        "IsQualified": "1",
        "LeadBy": 0,
        "Deficit": 0,
        "Performance": "L,L,L,W,W"
      },
      {
        "IPLYear": 2021,
        "Category": "IPL",
        "CompetitionID": "2021",
        "TeamID": "",
        "TeamCode": "RCB",
        "TeamName": "Royal Challengers Bangalore",
        "TeamLogo": "https://scores.iplt20.com/ipl/teamlogos/RCB.png",
        "Matches": 14,
        "Wins": 9,
        "Loss": 5,
        "Tied": 0,
        "NoResult": 0,
        "Points": 18,
        "Draw": 0,
        "ForTeams": "2165/273.4",
        "AgainstTeam": "2159/268.1",
        "NetRunRate": "-0.14",
        "Quotient": 0,
        "OrderNo": "3",
        "IsQualified": "1",
        "LeadBy": 0,
        "Deficit": 0,
        "Performance": "W,L,W,W,W"
      },
      {
        "IPLYear": 2021,
        "Category": "IPL",
        "CompetitionID": "2021",
        "TeamID": "",
        "TeamCode": "KKR",
        "TeamName": "Kolkata Knight Riders",
        "TeamLogo": "https://scores.iplt20.com/ipl/teamlogos/KKR.png",
        "Matches": 14,
        "Wins": 7,
        "Loss": 7,
        "Tied": 0,
        "NoResult": 0,
        "Points": 14,
        "Draw": 0,
        "ForTeams": "2119/259.5",
        "AgainstTeam": "2080/274.5",
        "NetRunRate": "0.587",
        "Quotient": 0,
        "OrderNo": "4",
        "IsQualified": "1",
        "LeadBy": 0,
        "Deficit": 0,
        "Performance": "W,W,L,W,L"
      },
      {
        "IPLYear": 2021,
        "Category": "IPL",
        "CompetitionID": "2021",
        "TeamID": "",
        "TeamCode": "MI",
        "TeamName": "Mumbai Indians",
        "TeamLogo": "https://scores.iplt20.com/ipl/teamlogos/MI.png",
        "Matches": 14,
        "Wins": 7,
        "Loss": 7,
        "Tied": 0,
        "NoResult": 0,
        "Points": 14,
        "Draw": 0,
        "ForTeams": "2117/265.5",
        "AgainstTeam": "2128/271.1",
        "NetRunRate": "0.116",
        "Quotient": 0,
        "OrderNo": "5",
        "IsQualified": "",
        "LeadBy": 0,
        "Deficit": 0,
        "Performance": "W,W,L,W,L"
      },
      {
        "IPLYear": 2021,
        "Category": "IPL",
        "CompetitionID": "2021",
        "TeamID": "",
        "TeamCode": "PBKS",
        "TeamName": "Punjab Kings",
        "TeamLogo": "https://scores.iplt20.com/ipl/teamlogos/PBKS.png",
        "Matches": 14,
        "Wins": 6,
        "Loss": 8,
        "Tied": 0,
        "NoResult": 0,
        "Points": 12,
        "Draw": 0,
        "ForTeams": "2150/270.1",
        "AgainstTeam": "2117/266",
        "NetRunRate": "-0.001",
        "Quotient": 0,
        "OrderNo": "6",
        "IsQualified": "",
        "LeadBy": 0,
        "Deficit": 0,
        "Performance": "W,L,W,L,W"
      },
      {
        "IPLYear": 2021,
        "Category": "IPL",
        "CompetitionID": "2021",
        "TeamID": "",
        "TeamCode": "RR",
        "TeamName": "Rajasthan Royals",
        "TeamLogo": "https://scores.iplt20.com/ipl/teamlogos/RR.png",
        "Matches": 14,
        "Wins": 5,
        "Loss": 9,
        "Tied": 0,
        "NoResult": 0,
        "Points": 10,
        "Draw": 0,
        "ForTeams": "2196/276",
        "AgainstTeam": "2318/259",
        "NetRunRate": "-0.993",
        "Quotient": 0,
        "OrderNo": "7",
        "IsQualified": "",
        "LeadBy": 0,
        "Deficit": 0,
        "Performance": "L,L,W,L,L"
      },
      {
        "IPLYear": 2021,
        "Category": "IPL",
        "CompetitionID": "2021",
        "TeamID": "",
        "TeamCode": "SRH",
        "TeamName": "Sunrisers Hyderabad",
        "TeamLogo": "https://scores.iplt20.com/ipl/teamlogos/SRH.png",
        "Matches": 14,
        "Wins": 3,
        "Loss": 11,
        "Tied": 0,
        "NoResult": 0,
        "Points": 6,
        "Draw": 0,
        "ForTeams": "2077/277.1",
        "AgainstTeam": "2216/275.4",
        "NetRunRate": "-0.545",
        "Quotient": 0,
        "OrderNo": "8",
        "IsQualified": "",
        "LeadBy": 0,
        "Deficit": 0,
        "Performance": "L,W,L,L,W"
      }
    ]
  }