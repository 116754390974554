export const m22 = {
    "points": [
      {
        "IPLYear": 2022,
        "StandingFlag": "NET",
        "Category": null,
        "CompetitionID": "60",
        "TeamID": "35",
        "TeamCode": "GT",
        "TeamName": "Gujarat Titans",
        "TeamLogo": "https://scores.iplt20.com/ipl/teamlogos/GT.png?v=1",
        "IsQualified": "1",
        "Matches": "14",
        "Wins": "10",
        "Loss": "4",
        "Tied": "0",
        "NoResult": "0",
        "Points": "20",
        "Draw": "0",
        "ForTeams": "2339/278.1",
        "AgainstTeam": "2216/273.5",
        "NetRunRate": "0.316",
        "Quotient": "1.180",
        "OrderNo": "1",
        "LeadBy": "0",
        "Deficit": "0",
        "Performance": "L,L,W,W,L"
      },
      {
        "IPLYear": 2022,
        "StandingFlag": "NET",
        "Category": null,
        "CompetitionID": "60",
        "TeamID": "18",
        "TeamCode": "RR",
        "TeamName": "Rajasthan Royals",
        "TeamLogo": "https://scores.iplt20.com/ipl/teamlogos/RR.png",
        "Matches": "14",
        "Wins": "9",
        "Loss": "5",
        "Tied": "0",
        "NoResult": "0",
        "Points": "18",
        "Draw": "0",
        "ForTeams": "2464/279.2",
        "AgainstTeam": "2351/275.5",
        "NetRunRate": "0.298",
        "Quotient": "1.193",
        "OrderNo": "2",
        "IsQualified": "1",
        "LeadBy": "0",
        "Deficit": "0",
        "Performance": "L,W,L,W,W"
      },
      {
        "IPLYear": 2022,
        "StandingFlag": "NET",
        "Category": null,
        "CompetitionID": "60",
        "TeamID": "77",
        "TeamCode": "LSG",
        "TeamName": "Lucknow Super Giants",
        "TeamLogo": "https://scores.iplt20.com/ipl/teamlogos/LSG.png",
        "Matches": "14",
        "Wins": "9",
        "Loss": "5",
        "Tied": "0",
        "NoResult": "0",
        "Points": "18",
        "Draw": "0",
        "ForTeams": "2355/279.1",
        "AgainstTeam": "2289/279.4",
        "NetRunRate": "0.251",
        "Quotient": "1.189",
        "OrderNo": "3",
        "IsQualified": "1",
        "LeadBy": "0",
        "Deficit": "0",
        "Performance": "W,W,L,L,W"
      },
      {
        "IPLYear": 2022,
        "StandingFlag": "NET",
        "Category": null,
        "CompetitionID": "60",
        "TeamID": "19",
        "TeamCode": "RCB",
        "TeamName": "Royal Challengers Bangalore",
        "TeamLogo": "https://scores.iplt20.com/ipl/teamlogos/RCB.png",
        "Matches": "14",
        "Wins": "8",
        "Loss": "6",
        "Tied": "0",
        "NoResult": "0",
        "Points": "16",
        "Draw": "0",
        "ForTeams": "2268/275.4",
        "AgainstTeam": "2260/266.3",
        "NetRunRate": "-0.253",
        "Quotient": "1.026",
        "OrderNo": "4",
        "IsQualified": "1",
        "LeadBy": "0",
        "Deficit": "0",
        "Performance": "L,W,W,L,W"
      },
      {
        "IPLYear": 2022,
        "StandingFlag": "NET",
        "Category": null,
        "CompetitionID": "60",
        "TeamID": "14",
        "TeamCode": "DC",
        "TeamName": "Delhi Capitals",
        "TeamLogo": "https://scores.iplt20.com/ipl/teamlogos/DC.png",
        "Matches": "14",
        "Wins": "7",
        "Loss": "7",
        "Tied": "0",
        "NoResult": "0",
        "Points": "14",
        "Draw": "0",
        "ForTeams": "2341/266.0",
        "AgainstTeam": "2397/278.5",
        "NetRunRate": "0.204",
        "Quotient": "1.061",
        "OrderNo": "5",
        "IsQualified": null,
        "LeadBy": "0",
        "Deficit": "0",
        "Performance": "W,L,W,W,L"
      },
      {
        "IPLYear": 2022,
        "StandingFlag": "NET",
        "Category": null,
        "CompetitionID": "60",
        "TeamID": "15",
        "TeamCode": "PBKS",
        "TeamName": "Punjab Kings",
        "TeamLogo": "https://scores.iplt20.com/ipl/teamlogos/PBKS.png",
        "Matches": "14",
        "Wins": "7",
        "Loss": "7",
        "Tied": "0",
        "NoResult": "0",
        "Points": "14",
        "Draw": "0",
        "ForTeams": "2343/270.1",
        "AgainstTeam": "2252/263.3",
        "NetRunRate": "0.126",
        "Quotient": "0.872",
        "OrderNo": "6",
        "IsQualified": null,
        "LeadBy": "0",
        "Deficit": "0",
        "Performance": "W,L,W,L,W"
      },
      {
        "IPLYear": 2022,
        "StandingFlag": "NET",
        "Category": null,
        "CompetitionID": "60",
        "TeamID": "16",
        "TeamCode": "KKR",
        "TeamName": "Kolkata Knight Riders",
        "TeamLogo": "https://scores.iplt20.com/ipl/teamlogos/KKR.png",
        "Matches": "14",
        "Wins": "6",
        "Loss": "8",
        "Tied": "0",
        "NoResult": "0",
        "Points": "12",
        "Draw": "0",
        "ForTeams": "2223/268.1",
        "AgainstTeam": "2249/276.1",
        "NetRunRate": "0.146",
        "Quotient": "0.798",
        "OrderNo": "7",
        "IsQualified": null,
        "LeadBy": "0",
        "Deficit": "0",
        "Performance": "W,L,W,W,L"
      },
      {
        "IPLYear": 2022,
        "StandingFlag": "NET",
        "Category": null,
        "CompetitionID": "60",
        "TeamID": "20",
        "TeamCode": "SRH",
        "TeamName": "Sunrisers Hyderabad",
        "TeamLogo": "https://scores.iplt20.com/ipl/teamlogos/SRH.png",
        "Matches": "14",
        "Wins": "6",
        "Loss": "8",
        "Tied": "0",
        "NoResult": "0",
        "Points": "12",
        "Draw": "0",
        "ForTeams": "2197/261.3",
        "AgainstTeam": "2416/275.1",
        "NetRunRate": "-0.379",
        "Quotient": "0.989",
        "OrderNo": "8",
        "IsQualified": null,
        "LeadBy": "0",
        "Deficit": "0",
        "Performance": "L,L,L,W,L"
      },
      {
        "IPLYear": 2022,
        "StandingFlag": "NET",
        "Category": null,
        "CompetitionID": "60",
        "TeamID": "13",
        "TeamCode": "CSK",
        "TeamName": "Chennai Super Kings",
        "TeamLogo": "https://scores.iplt20.com/ipl/teamlogos/CSK.png",
        "Matches": "14",
        "Wins": "4",
        "Loss": "10",
        "Tied": "0",
        "NoResult": "0",
        "Points": "8",
        "Draw": "0",
        "ForTeams": "2288/280.0",
        "AgainstTeam": "2254/269.1",
        "NetRunRate": "-0.203",
        "Quotient": "0.945",
        "OrderNo": "9",
        "IsQualified": null,
        "LeadBy": "0",
        "Deficit": "0",
        "Performance": "L,W,L,L,L"
      },
      {
        "IPLYear": 2022,
        "StandingFlag": "NET",
        "Category": null,
        "CompetitionID": "60",
        "TeamID": "17",
        "TeamCode": "MI",
        "TeamName": "Mumbai Indians",
        "TeamLogo": "https://scores.iplt20.com/ipl/teamlogos/MI.png",
        "Matches": "14",
        "Wins": "4",
        "Loss": "10",
        "Tied": "0",
        "NoResult": "0",
        "Points": "8",
        "Draw": "0",
        "ForTeams": "2217/273.2",
        "AgainstTeam": "2351/272.5",
        "NetRunRate": "-0.506",
        "Quotient": "0.872",
        "OrderNo": "10",
        "IsQualified": null,
        "LeadBy": "0",
        "Deficit": "0",
        "Performance": "W,L,W,L,W"
      }
    ]
  }