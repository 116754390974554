import { create } from "zustand";

export const useStore = create((set) => ({
  data: {
    teamsData: [],
    columnDetails: [],
    options:[],
    genderOptions: [],
    yearOptions: [],
    gender: "",
    year: "",
  },
  setTeamsData: (value) =>
    set((state) => ({ ...state.teamsData, teamsData: value })),

  setColumnDetails: (value) => {
    set((state) => ({
      ...state.columnDetails,
      columnDetails: value,
    }));
  },

  setOptions: (value) =>
    set((state) => ({
      ...state.options,
      options: value,
    })),

  setGenderOptions: (value) =>
    set((state) => ({
      ...state.genderOptions,
      genderOptions: value,
    })),

  setYearOptions: (value) =>
    set((state) => ({
      ...state.yearOptions,
      yearOptions: value,
    })),

    setGender:(value)=>{
      set((state)=>({
        ...state.gender,
        gender:value
      }))
    },

    setYear:(value)=>{
      set((state)=>({
        ...state.year,
        year:value
      }))
    }
}));
