import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { OrganizationChart } from "primereact/organizationchart";
import "./details.css";
import {
  rr,
  rcb,
  csk,
  srh,
  mi,
  lsg,
  pbks,
  kkr,
  vel,
  dc,
  gt,
  sno,
  tbl,
} from "../players/players.data";

const Details = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [data, setData] = useState();

  async function fetchData() {
    const response = await fetch(
      `https://www.mockachino.com/5db99bd2-28c5-46/ipl/list/players`
    );
    const res = await response.json();
    // setData(res.crew);
  }

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    console.log(params.team);
    if (params.team === "csk") {
      setData(csk.crew);
    } else if (params.team == "rr") {
      setData(rr.crew);
    } else if (params.team === "rcb") {
      setData(rcb.crew);
    } else if (params.team === "pbks") {
      setData(pbks.crew);
    } else if (params.team === "mi") {
      setData(mi.crew);
    } else if (params.team === "srh") {
      setData(srh.crew);
    } else if (params.team === "lsg") {
      setData(lsg.crew);
    } else if (params.team === "kkr") {
      setData(kkr.crew);
    } else if (params.team === "vel") {
      setData(vel.crew);
    } else if (params.team === "dc") {
      setData(dc.crew);
    } else if (params.team === "gt") {
      setData(gt.crew);
    }
    else if (params.team === "tbl") {
      setData(tbl.crew);
    } else if (params.team === "sno") {
      setData(sno.crew);
    }
  }, [params]);

  const nodeTemplate = (node) => {
    return (
      <div className="node">
        <img
          alt={node.label}
          src={node.image}
          style={{ width: "2.5rem", height: "2.5rem" }}
          loading="lazy"
        />
        <div>{node.label}</div>
      </div>
    );
  };
  return (
    <div className="org-chart">
      <div style={{ maxWidth: "10px" }}>
        {data && data.length && (
          <OrganizationChart value={data} nodeTemplate={nodeTemplate} />
        )}
      </div>
    </div>
  );
};

export default Details;
