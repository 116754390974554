export const w22 = {
    "points": [
      {
        "IPLYear": 2022,
        "StandingFlag": "NET",
        "Category": null,
        "CompetitionID": "62",
        "TeamID": "81",
        "TeamCode": "SNO",
        "TeamName": "Supernovas",
        "TeamLogo": "https://scores.iplt20.com/ipl/teamlogos/SNRoundbig.png",
        "IsQualified": "1",
        "Matches": "2",
        "Wins": "1",
        "Loss": "1",
        "Tied": "0",
        "NoResult": "0",
        "Points": "2",
        "Draw": "0",
        "ForTeams": "313/40.0",
        "AgainstTeam": "265/38.2",
        "NetRunRate": "0.912",
        "Quotient": "0.944",
        "OrderNo": "1",
        "LeadBy": "0",
        "Deficit": "0",
        "Performance": "W,L"
      },
      {
        "IPLYear": 2022,
        "StandingFlag": "NET",
        "Category": null,
        "CompetitionID": "62",
        "TeamID": "82",
        "TeamCode": "VEL",
        "TeamName": "Velocity",
        "TeamLogo": "https://scores.iplt20.com/ipl/teamlogos/VelocityRB.png",
        "Matches": "2",
        "Wins": "1",
        "Loss": "1",
        "Tied": "0",
        "NoResult": "0",
        "Points": "2",
        "Draw": "0",
        "ForTeams": "325/38.2",
        "AgainstTeam": "340/40.0",
        "NetRunRate": "-0.022",
        "Quotient": "0.796",
        "OrderNo": "2",
        "IsQualified": "1",
        "LeadBy": "0",
        "Deficit": "0",
        "Performance": "W,L"
      },
      {
        "IPLYear": 2022,
        "StandingFlag": "NET",
        "Category": null,
        "CompetitionID": "62",
        "TeamID": "80",
        "TeamCode": "TBL",
        "TeamName": "Trailblazers",
        "TeamLogo": "https://scores.iplt20.com/ipl/teamlogos/TBRoundbig.png",
        "Matches": "2",
        "Wins": "1",
        "Loss": "1",
        "Tied": "0",
        "NoResult": "0",
        "Points": "2",
        "Draw": "0",
        "ForTeams": "304/40.0",
        "AgainstTeam": "337/40.0",
        "NetRunRate": "-0.825",
        "Quotient": "1.224",
        "OrderNo": "3",
        "IsQualified": null,
        "LeadBy": "0",
        "Deficit": "0",
        "Performance": "L,W"
      }
    ]
  }