import "./App.css";
import Home from "./pages/home/home";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Details from "./pages/details";
import Layout from "./components/layout";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <Routes>
          <Route>
            <Route path="/" element={<Layout />}>
              <Route path="" element={<Navigate to={"/home/points-table"} />} />
              <Route path="/home/points-table" element={<Home />} />
              <Route
                path="/home/points-table/detail/:team"
                element={<Details />}
              />
            </Route>
            <Route path="*" element={<Navigate to="/home/points-table" />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </QueryClientProvider>
  );
}

export default App;
