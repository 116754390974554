import { Toolbar } from "primereact/toolbar";
import { Link, useNavigate } from "react-router-dom";
import React from "react";
import logo from "../assets/tcc-logo.png"
import { Button } from "primereact/button";

function Topbar() {
  const navigate = useNavigate();
  const start=(
    <img src={logo} alt="tcc-logo" height={48}/>
  )

  const end=(
    <div style={{ display: 'flex', gap: '2rem' }}>
        <div style={{ cursor: 'pointer'}} onClick={() => navigate('/home/points-table')}>Home</div>
        {/* <div style={{ cursor: 'pointer'}} onClick={() => navigate(-1)}>Back</div> */}
        <div>
            <Link target="_blank" to={"http://thecloud.company/"}>
            <i className="pi pi-globe" style={{margin:'0 1rem',cursor:'pointer'}}></i>
            </Link>
        </div>
    </div>
    
  )

  return (
    <div>
      <Toolbar start={start} end={end} style={{padding:"0",width:"100%"}} />
    </div>
  );
}

export default Topbar;
