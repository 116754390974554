export const w20 = {
  points: [
    {
      IPLYear: 2020,
      StandingFlag: "",
      Category: "IPL",
      CompetitionID: 10016,
      TeamID: "1",
      TeamCode: "TBL",
      TeamName: "Trailblazers",
      TeamLogo: "https://scores.iplt20.com/ipl/teamlogos/TBL.png?v=2",
      IsQualified: null,
      Matches: 2,
      Wins: 1,
      Loss: 1,
      Tied: 0,
      NoResult: 0,
      Points: "2",
      Draw: 0,
      ForTeams: "193/27.5",
      AgainstTeam: "193/40",
      NetRunRate: "+2.109",
      Quotient: "",
      OrderNo: 1,
      LeadBy: 0,
      Deficit: 0,
      Performance: "W,L",
    },
    {
      IPLYear: 2020,
      StandingFlag: "",
      Category: "IPL",
      CompetitionID: 10016,
      TeamID: "2",
      TeamCode: "SNO",
      TeamName: "Supernovas",
      TeamLogo: "https://scores.iplt20.com/ipl/teamlogos/SNO.png?v=2",
      Matches: 2,
      Wins: 1,
      Loss: 1,
      Tied: 0,
      NoResult: 0,
      Points: "2",
      Draw: 0,
      ForTeams: "272/40",
      AgainstTeam: "273/39.5",
      NetRunRate: "-0.054",
      Quotient: "",
      OrderNo: 2,
      IsQualified: null,
      LeadBy: 0,
      Deficit: 0,
      Performance: "L,W",
    },
    {
      IPLYear: 2020,
      StandingFlag: "",
      Category: "IPL",
      CompetitionID: 10016,
      TeamID: "3",
      TeamCode: "VEL",
      TeamName: "Velocity",
      TeamLogo: "https://scores.iplt20.com/ipl/teamlogos/VEL.png?v=2",
      Matches: 2,
      Wins: 1,
      Loss: 1,
      Tied: 0,
      NoResult: 0,
      Points: "2",
      Draw: 0,
      ForTeams: "176/39.5",
      AgainstTeam: "175/27.5",
      NetRunRate: -1.869,
      Quotient: "",
      OrderNo: 3,
      IsQualified: null,
      LeadBy: 0,
      Deficit: 0,
      Performance: "W,L",
    },
  ],
};
