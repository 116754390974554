export function getGenderOptions(options) {
  const genderOption = [];
  options.forEach((item) => {
    let obj = {
      value: item.gender,
      label:item.gender
    };
    genderOption.push(obj);
  });
  return genderOption;
}

export function getYearOptions(options) {
  let arr = [];
  let yearOptions = [];
  options.forEach((item) => {
    arr = arr.concat(item.seasons);
  });
  arr = [...new Set(arr)];
  arr.forEach((item) => {
    let obj = {
      value: item,
      label:item
    };
    yearOptions.push(obj);
  });

  return yearOptions;
}
