export const m23 = {
    "points": [
      {
        "IPLYear": 2023,
        "Category": null,
        "CompetitionID": "107",
        "TeamID": "35",
        "TeamCode": "GT",
        "TeamName": "Gujarat Titans",
        "TeamLogo": "https://scores.iplt20.com/ipl/teamlogos/GT.png?v=1",
        "IsQualified": "1",
        "Matches": "14",
        "Wins": "10",
        "Loss": "4",
        "Tied": "0",
        "NoResult": "0",
        "Points": "20",
        "Draw": "0",
        "ForTeams": "2450/268.1",
        "AgainstTeam": "2326/279.2",
        "NetRunRate": "0.809",
        "Quotient": "1.587",
        "OrderNo": "1",
        "LeadBy": "0",
        "Deficit": "0",
        "Performance": "W,W,L,W,W"
      },
      {
        "IPLYear": 2023,
        "Category": null,
        "CompetitionID": "107",
        "TeamID": "13",
        "TeamCode": "CSK",
        "TeamName": "Chennai Super Kings",
        "TeamLogo": "https://scores.iplt20.com/ipl/teamlogos/CSK.png",
        "Matches": "14",
        "Wins": "8",
        "Loss": "5",
        "Tied": "0",
        "NoResult": "1",
        "Points": "17",
        "Draw": "0",
        "ForTeams": "2369/254.3",
        "AgainstTeam": "2232/257.5",
        "NetRunRate": "0.652",
        "Quotient": "1.441",
        "OrderNo": "2",
        "IsQualified": "1",
        "LeadBy": "0",
        "Deficit": "0",
        "Performance": "N,W,W,L,W"
      },
      {
        "IPLYear": 2023,
        "Category": null,
        "CompetitionID": "107",
        "TeamID": "77",
        "TeamCode": "LSG",
        "TeamName": "Lucknow Super Giants",
        "TeamLogo": "https://feeds-100mb.s3-ap-southeast-1.amazonaws.com/teamLogos/ADAMetmKk61682948573LSG.png",
        "Matches": "14",
        "Wins": "8",
        "Loss": "5",
        "Tied": "0",
        "NoResult": "1",
        "Points": "17",
        "Draw": "0",
        "ForTeams": "2253/255.2",
        "AgainstTeam": "2216/259.3",
        "NetRunRate": "0.284",
        "Quotient": "1.016",
        "OrderNo": "3",
        "IsQualified": "1",
        "LeadBy": "0",
        "Deficit": "0",
        "Performance": "N,L,W,W,W"
      },
      {
        "IPLYear": 2023,
        "Category": null,
        "CompetitionID": "107",
        "TeamID": "17",
        "TeamCode": "MI",
        "TeamName": "Mumbai Indians",
        "TeamLogo": "https://scores.iplt20.com/ipl/teamlogos/MI.png",
        "Matches": "14",
        "Wins": "8",
        "Loss": "6",
        "Tied": "0",
        "NoResult": "0",
        "Points": "16",
        "Draw": "0",
        "ForTeams": "2592/270.3",
        "AgainstTeam": "2620/272.1",
        "NetRunRate": "-0.044",
        "Quotient": "1.054",
        "OrderNo": "4",
        "IsQualified": "1",
        "LeadBy": "0",
        "Deficit": "0",
        "Performance": "L,W,W,L,W"
      },
      {
        "IPLYear": 2023,
        "Category": null,
        "CompetitionID": "107",
        "TeamID": "18",
        "TeamCode": "RR",
        "TeamName": "Rajasthan Royals",
        "TeamLogo": "https://scores.iplt20.com/ipl/teamlogos/RR.png",
        "Matches": "14",
        "Wins": "7",
        "Loss": "7",
        "Tied": "0",
        "NoResult": "0",
        "Points": "14",
        "Draw": "0",
        "ForTeams": "2419/272.1",
        "AgainstTeam": "2389/273.2",
        "NetRunRate": "0.148",
        "Quotient": "1.024",
        "OrderNo": "5",
        "IsQualified": null,
        "LeadBy": "0",
        "Deficit": "0",
        "Performance": "L,L,W,L,W"
      },
      {
        "IPLYear": 2023,
        "Category": null,
        "CompetitionID": "107",
        "TeamID": "19",
        "TeamCode": "RCB",
        "TeamName": "Royal Challengers Bangalore",
        "TeamLogo": "https://scores.iplt20.com/ipl/teamlogos/RCB.png",
        "Matches": "14",
        "Wins": "7",
        "Loss": "7",
        "Tied": "0",
        "NoResult": "0",
        "Points": "14",
        "Draw": "0",
        "ForTeams": "2502/275.4",
        "AgainstTeam": "2435/272.2",
        "NetRunRate": "0.135",
        "Quotient": "1.220",
        "OrderNo": "6",
        "IsQualified": null,
        "LeadBy": "0",
        "Deficit": "0",
        "Performance": "L,L,W,W,L"
      },
      {
        "IPLYear": 2023,
        "Category": null,
        "CompetitionID": "107",
        "TeamID": "16",
        "TeamCode": "KKR",
        "TeamName": "Kolkata Knight Riders",
        "TeamLogo": "https://scores.iplt20.com/ipl/teamlogos/KKR.png",
        "Matches": "14",
        "Wins": "6",
        "Loss": "8",
        "Tied": "0",
        "NoResult": "0",
        "Points": "12",
        "Draw": "0",
        "ForTeams": "2463/274.3",
        "AgainstTeam": "2432/264.0",
        "NetRunRate": "-0.239",
        "Quotient": "0.824",
        "OrderNo": "7",
        "IsQualified": null,
        "LeadBy": "0",
        "Deficit": "0",
        "Performance": "W,W,L,W,L"
      },
      {
        "IPLYear": 2023,
        "Category": null,
        "CompetitionID": "107",
        "TeamID": "15",
        "TeamCode": "PBKS",
        "TeamName": "Punjab Kings",
        "TeamLogo": "https://scores.iplt20.com/ipl/teamlogos/PBKS.png",
        "Matches": "14",
        "Wins": "6",
        "Loss": "8",
        "Tied": "0",
        "NoResult": "0",
        "Points": "12",
        "Draw": "0",
        "ForTeams": "2518/275.3",
        "AgainstTeam": "2564/271.3",
        "NetRunRate": "-0.304",
        "Quotient": "0.721",
        "OrderNo": "8",
        "IsQualified": null,
        "LeadBy": "0",
        "Deficit": "0",
        "Performance": "L,L,W,L,L"
      },
      {
        "IPLYear": 2023,
        "Category": null,
        "CompetitionID": "107",
        "TeamID": "14",
        "TeamCode": "DC",
        "TeamName": "Delhi Capitals",
        "TeamLogo": "https://scores.iplt20.com/ipl/teamlogos/DC.png",
        "Matches": "14",
        "Wins": "5",
        "Loss": "9",
        "Tied": "0",
        "NoResult": "0",
        "Points": "10",
        "Draw": "0",
        "ForTeams": "2182/276.0",
        "AgainstTeam": "2424/278.1",
        "NetRunRate": "-0.808",
        "Quotient": "0.709",
        "OrderNo": "9",
        "IsQualified": null,
        "LeadBy": "0",
        "Deficit": "0",
        "Performance": "W,L,L,W,L"
      },
      {
        "IPLYear": 2023,
        "Category": null,
        "CompetitionID": "107",
        "TeamID": "20",
        "TeamCode": "SRH",
        "TeamName": "Sunrisers Hyderabad",
        "TeamLogo": "https://scores.iplt20.com/ipl/teamlogos/SRH.png",
        "Matches": "14",
        "Wins": "4",
        "Loss": "10",
        "Tied": "0",
        "NoResult": "0",
        "Points": "8",
        "Draw": "0",
        "ForTeams": "2376/277.1",
        "AgainstTeam": "2486/271.2",
        "NetRunRate": "-0.590",
        "Quotient": "0.807",
        "OrderNo": "10",
        "IsQualified": null,
        "LeadBy": "0",
        "Deficit": "0",
        "Performance": "W,L,L,L,L"
      }
    ]
  }