export const m20 = {
    "points": [
      {
        "IPLYear": 2020,
        "Category": "IPL",
        "CompetitionID": "2020",
        "TeamID": "",
        "TeamCode": "MI",
        "TeamName": "Mumbai Indians",
        "TeamLogo": "https://scores.iplt20.com/ipl/teamlogos/MI.png",
        "IsQualified": "1",
        "Matches": 14,
        "Wins": 9,
        "Loss": 5,
        "Tied": 0,
        "NoResult": 0,
        "Points": 18,
        "Draw": 0,
        "ForTeams": "2378/262.2",
        "AgainstTeam": "2187/274.5",
        "NetRunRate": "1.107",
        "Quotient": 0,
        "OrderNo": "1",
        "LeadBy": 0,
        "Deficit": 0,
        "Performance": "L,W,W,L,W"
      },
      {
        "IPLYear": 2020,
        "Category": "IPL",
        "CompetitionID": "2020",
        "TeamID": "",
        "TeamCode": "DC",
        "TeamName": "Delhi Capitals",
        "TeamLogo": "https://scores.iplt20.com/ipl/teamlogos/DC.png",
        "Matches": 14,
        "Wins": 8,
        "Loss": 6,
        "Tied": 0,
        "NoResult": 0,
        "Points": 16,
        "Draw": 0,
        "ForTeams": "2289/278.5",
        "AgainstTeam": "2271/273",
        "NetRunRate": "-0.109",
        "Quotient": 0,
        "OrderNo": "2",
        "IsQualified": "1",
        "LeadBy": 0,
        "Deficit": 0,
        "Performance": "W,L,L,L,L"
      },
      {
        "IPLYear": 2020,
        "Category": "IPL",
        "CompetitionID": "2020",
        "TeamID": "",
        "TeamCode": "SRH",
        "TeamName": "Sunrisers Hyderabad",
        "TeamLogo": "https://scores.iplt20.com/ipl/teamlogos/SRH.png",
        "Matches": 14,
        "Wins": 7,
        "Loss": 7,
        "Tied": 0,
        "NoResult": 0,
        "Points": 14,
        "Draw": 0,
        "ForTeams": "2225/269.3",
        "AgainstTeam": "2125/277.5",
        "NetRunRate": "0.608",
        "Quotient": 0,
        "OrderNo": "3",
        "IsQualified": "1",
        "LeadBy": 0,
        "Deficit": 0,
        "Performance": "W,W,W,L,W"
      },
      {
        "IPLYear": 2020,
        "Category": "IPL",
        "CompetitionID": "2020",
        "TeamID": "",
        "TeamCode": "RCB",
        "TeamName": "Royal Challengers Bangalore",
        "TeamLogo": "https://scores.iplt20.com/ipl/teamlogos/RCB.png",
        "Matches": 14,
        "Wins": 7,
        "Loss": 7,
        "Tied": 0,
        "NoResult": 0,
        "Points": 14,
        "Draw": 0,
        "ForTeams": "2147/272.2",
        "AgainstTeam": "2183/271",
        "NetRunRate": "-0.172",
        "Quotient": 0,
        "OrderNo": "4",
        "IsQualified": "1",
        "LeadBy": 0,
        "Deficit": 0,
        "Performance": "L,L,L,L,W"
      },
      {
        "IPLYear": 2020,
        "Category": "IPL",
        "CompetitionID": "2020",
        "TeamID": "",
        "TeamCode": "KKR",
        "TeamName": "Kolkata Knight Riders",
        "TeamLogo": "https://scores.iplt20.com/ipl/teamlogos/KKR.png",
        "Matches": 14,
        "Wins": 7,
        "Loss": 7,
        "Tied": 0,
        "NoResult": 0,
        "Points": 14,
        "Draw": 0,
        "ForTeams": "2219/278",
        "AgainstTeam": "2206/269.1",
        "NetRunRate": "-0.214",
        "Quotient": 0,
        "OrderNo": "5",
        "IsQualified": "",
        "LeadBy": 0,
        "Deficit": 0,
        "Performance": "W,L,L,W,L"
      },
      {
        "IPLYear": 2020,
        "Category": "IPL",
        "CompetitionID": "2020",
        "TeamID": "",
        "TeamCode": "PBKS",
        "TeamName": "Punjab Kings",
        "TeamLogo": "https://scores.iplt20.com/ipl/teamlogos/PBKS.png",
        "Matches": 14,
        "Wins": 6,
        "Loss": 8,
        "Tied": 0,
        "NoResult": 0,
        "Points": 12,
        "Draw": 0,
        "ForTeams": "2335/277.5",
        "AgainstTeam": "2343/273.3",
        "NetRunRate": "-0.162",
        "Quotient": 0,
        "OrderNo": "6",
        "IsQualified": "",
        "LeadBy": 0,
        "Deficit": 0,
        "Performance": "L,L,W,W,W"
      },
      {
        "IPLYear": 2020,
        "Category": "IPL",
        "CompetitionID": "2020",
        "TeamID": "",
        "TeamCode": "CSK",
        "TeamName": "Chennai Super Kings",
        "TeamLogo": "https://scores.iplt20.com/ipl/teamlogos/CSK.png",
        "Matches": 14,
        "Wins": 6,
        "Loss": 8,
        "Tied": 0,
        "NoResult": 0,
        "Points": 12,
        "Draw": 0,
        "ForTeams": "2191/274.3",
        "AgainstTeam": "2275/269.4",
        "NetRunRate": "-0.455",
        "Quotient": 0,
        "OrderNo": "7",
        "IsQualified": "",
        "LeadBy": 0,
        "Deficit": 0,
        "Performance": "W,W,W,L,L"
      },
      {
        "IPLYear": 2020,
        "Category": "IPL",
        "CompetitionID": "2020",
        "TeamID": "",
        "TeamCode": "RR",
        "TeamName": "Rajasthan Royals",
        "TeamLogo": "https://scores.iplt20.com/ipl/teamlogos/RR.png",
        "Matches": 14,
        "Wins": 6,
        "Loss": 8,
        "Tied": 0,
        "NoResult": 0,
        "Points": 12,
        "Draw": 0,
        "ForTeams": "2288/272.4",
        "AgainstTeam": "2482/277",
        "NetRunRate": "-0.569",
        "Quotient": 0,
        "OrderNo": "8",
        "IsQualified": "",
        "LeadBy": 0,
        "Deficit": 0,
        "Performance": "L,W,W,L,W"
      }
    ]
  }