import React, { useEffect, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useQuery } from "@tanstack/react-query";
import { ProgressSpinner } from "primereact/progressspinner";
import { m22 } from "../table/table_m22";
import { m21 } from "../table/table_m21";
import { m20 } from "../table/table_m20";
import { m23 } from "../table/table_m23";
import { w20 } from "../table/table_w20";
import { w22 } from "../table/table_w22";
import "primeicons/primeicons.css";
import { useNavigate } from "react-router-dom";
import { useStore } from "../store/store";


function TeamTable({ gender, year }) {
  const {teamsData,setTeamsData}=useStore()
  const navigate=useNavigate()
  const [tableData, setTableData] = useState();
  const { data, isFetched, isLoading } = useQuery({
    queryKey: ["teamDetails", gender, year],
    queryFn: () =>
      fetch(`https://www.mockachino.com/5db99bd2-28c5-46/ipl/table`).then(
        (res) => res.json()
      ),
  });

  useEffect(() => {
    if (gender === "Men" && year === 2020) {
      setTeamsData(m20);
    } else if (gender === "Men" && year === 2021) {
      setTeamsData(m21);
    } else if (gender === "Men" && year === 2022) {
      setTeamsData(m22);
    } else if (gender === "Men" && year === 2023) {
      setTeamsData(m23);
    } else if (gender === "Women" && year === 2020) {
      setTeamsData(w20);
    } else if (gender === "Women" && year === 2022) {
      setTeamsData(w22);
    }
  }, [gender, year]);

  const col = [
    {
      field: "TeamLogo",
      header: "",
      body: "imageBodyTemplate",
    },
    {
      field: "TeamCode",
      header: "Team",
      body: "textBodyTemplate",
    },
    {
      field: "Matches",
      header: "P",
      body: "textBodyTemplate",
    },
    {
      field: "Wins",
      header: "W",
      body: "textBodyTemplate",
    },
    {
      field: "Loss",
      header: "L",
      body: "textBodyTemplate",
    },
    {
      field: "NoResult",
      header: "NR",
      body: "textBodyTemplate",
    },
    {
      field: "NetRunRate",
      header: "NRR",
      body: "textBodyTemplate",
    },
    {
      field: "ForTeams",
      header: "FOR",
      body: "textBodyTemplate",
    },
    {
      field: "AgainstTeam",
      header: "AGAINST",
      body: "textBodyTemplate",
    },
    {
      field: "Points",
      header: "PTS",
      body: "textBodyTemplate",
    },
    {
      field: "Performance",
      header: "RECENTFORM",
      body: "textBodyTemplate",
    },
  ];
  const imageBodyTemplate = (col) =>
    col.IsQualified === "1" ? (
      <>
        <img src={col.TeamLogo} alt="" width={32} height={32} />
        <i className="pi pi-circle-fill" style={{ color: "green",margin:'0.5rem 0', fontSize: '0.5rem' }}></i>
      </>
    ) : (
      <img src={col.TeamLogo} alt="logo" width={32} height={32} />
    );

    const onRowClick = async (row) => {
      const TeamCode = row.data.TeamCode.toLowerCase();
      navigate(`/home/points-table/detail/${TeamCode}`);
    }

  return (
    <div className="card" style={{ width: "85%", margin: "auto" }}>
      {isFetched ? (
        <DataTable
          value={teamsData.points}
          // showGridlines
          onRowClick={onRowClick}
          selectionMode="single"
        >
          {col.map((col, i) =>
            col.body === "textBodyTemplate" ? (
              <Column key={col.field} field={col.field} header={col.header} />
            ) : (
              <Column
                key={col.field}
                field={col.field}
                header={col.header}
                body={imageBodyTemplate}
              />
            )
          )}
        </DataTable>
      ) : (
        <div style={{ width: "100%", margin: "auto", textAlign: "center" }}>
          <ProgressSpinner
            style={{ width: "50px", height: "50px" }}
            animationDuration="1s"
          />
        </div>
      )}
    </div>
  );
}

export default TeamTable;
