import React, { useEffect } from "react";
import { useStore } from "../../store/store";
import { getGenderOptions, getYearOptions } from "../../utils/home.utils";
import "./home.css";
import Select from "react-select";
import TeamTable from "../../components/dataTable";

function Home() {
  const {
    options,
    genderOptions,
    yearOptions,
    gender,
    year,
    setGenderOptions,
    setYearOptions,
    setYear,
    setGender,
    setOptions,
  } = useStore();

  async function fetchFilterOptions() {
    const data = {
      options: [
        { gender: "Men", seasons: [2020, 2021, 2022, 2023] },
        { gender: "Women", seasons: [2020, 2022] },
      ],
    };
    await setOptions(data.options);
    const genderOptionValues = getGenderOptions(data.options);
    const yearOptionValues = getYearOptions(data.options);
    await setGenderOptions(genderOptionValues);
    await setYearOptions(yearOptionValues);
  }
  useEffect(() => {
    fetchFilterOptions();
  }, []);

  useEffect(() => {
    if (options) {
      let arr = [];
      options.forEach((item) => {
        if (item.gender === gender) {
          item.seasons.forEach((ele) => {
            let obj = {
              value: ele,
              label: ele,
            };
            arr.push(obj);
          });
        }
      });
      setYearOptions(arr);
    }
  }, [gender]);

  return (
    <div>
      <header className="header">
        <h1>We welcome you all to</h1>
        <h3>The Cloud Company's Placement Drive - September 2023 Chapter</h3>
      </header>
      <div className="dropdown">
        <div className="gender-dropdown">
          <Select
            className="basic-single"
            classNamePrefix="select"
            name="gender"
            options={genderOptions}
            placeholder="Gender"
            onChange={(e) => setGender(e.value)}
          />
        </div>
        <div className="year-dropdown">
          <Select
            isDisabled={gender ? false : true}
            className="basic-single"
            classNamePrefix="select"
            name="year"
            options={yearOptions}
            placeholder="Season"
            onChange={(e) => setYear(e.value)}
          />
        </div>
      </div>
      {gender && year && <TeamTable gender={gender} year={year} />}
    </div>
  );
}

export default Home;
